<template>
    <!-- 提交合作意向弹框 -->
    <Modal
        v-model="show"
        :title="'提交合作意向'|language"
    >
        <Form
            :model="model"
            ref="form"
            :rules="ruleValidate"
            label-position="top"
        >
            <FormItem
                :label="'企业名：'|language"
                prop="enterpriseName"
            >
                <Input
                    v-model="model.enterpriseName"
                    :placeholder="'请填写企业名'|language"
                ></Input>
            </FormItem>
            <FormItem
                :label="'联系方式：'|language"
                prop="contact"
            >
                <Input
                    v-model="model.contact"
                    :placeholder="'请填写联系方式'|language"
                ></Input>
            </FormItem>
            <FormItem
                :label="'合作意向：'|language"
                prop="intention"
            >
                <Select
                    v-model="model.intention"
                    :placeholder="'请选择合作意向'|language"
                >
                    <Option
                        :value="item.value"
                        v-for="item in options"
                        :key="item.value"
                    >{{item.label|language}}</Option>

                </Select>
            </FormItem>
            <FormItem :label="'附件：'|language">
                <Upload
                    ref="upload"
                    multiple
                    :show-upload-list="true"
                    :data="qnUploadData"
                    :before-upload="beforeFileUpload"
                    :on-success="handleSelectFile"
                    :on-remove="removeFileList"
                    action="https://upload.qbox.me"
                >
                    <Button
                        type="primary"
                        icon="ios-cloud-upload-outline"
                        size="small"
                    >
                        {{"上传文件"|language}}</Button>
                </Upload>
            </FormItem>
        </Form>
        <div
            slot="footer"
            class="container-end"
        >
            <Button
                size="large"
                @click="show=false"
            >{{"取消"|language}}</Button>
            <Button
                type="primary"
                size="large"
                :loading="loading"
                @click="save"
            >{{"确定"|language}}</Button>
        </div>
    </Modal>
</template>

<script>
import uuidv1 from "uuid/v1";
import { qiniuUploadMixin } from "../../../mixin/qiniuUpload";
import { languageTranslation } from "../../../assets/util/language";
export default {
  mixins: [qiniuUploadMixin],
  data() {
    return {
      show: false,
      loading: false,
      model: {
        enterpriseName: "",
        contact: "",
        intention: "",
        attachment: ""
      },
      fileList: [],
      options: [
        { label: "共同开展课题研究", value: "research" },
        { label: "共同开设特色课程", value: "course" },
        { label: "支持电院创新竞赛", value: "competition" },
        { label: "创新创业培训与项目孵化", value: "project" },
        { label: "企业最新产品应用", value: "product" },
        { label: "合作开展人才培养（实习/毕设等）", value: "training" },
        { label: "其他", value: "other" }
      ],
      ruleValidate: {
        enterpriseName: [
          {
            required: true,

            message: languageTranslation("企业名不能为空"),
            trigger: "blur"
          }
        ],
        contact: [
          {
            required: true,

            message: languageTranslation("联系方式不能为空"),
            trigger: "blur"
          }
        ],
        intention: [
          {
            required: true,

            message: languageTranslation("请选择合作意向"),
            trigger: "change"
          }
        ]
      }
    };
  },

  methods: {
    //上传部分
    beforeFileUpload(file) {
      //上传前清空fileList
      this.$refs.upload.clearFiles();
      let ext = file.name.split(".").reverse()[0];
      if (!ext) {
        this.$Message.error({
          background: true,
          content: languageTranslation("不支持无后缀名文件上传")
        });
      }

      this.qnUploadData.key = `${uuidv1()}.${ext}`;
      let isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$Message.error({
          background: true,
          content: `${languageTranslation("上传文件大小不能超过")}10MB`
        });
        return false;
      }

      return isLt10M;
    },

    handleSelectFile(res, file) {
      let src = this.joinCDN(res.key);

      this.uploadFile(src, file.name, file.size);
    },
    async uploadFile(src, name, size) {
      this.model.attachment = src;
      // this.$Spin.show();
      //   await this.httpFileUpload(src, name, size);
      // this.$Spin.hide();
    },
    async httpFileUpload(src, name, size) {
      let type = name.split(".").reverse()[0];
      name = name.split(".").reverse()[1];
      let data = {
        folder: this.selectedFolder,
        src,
        type,
        name,
        permiss: 3,
        size
      };
      this.$Message.success(`${languageTranslation("上传成功")}`);
      let file = await HttpUtil.post("/filestore", data);
      this.info.fileList.push(file);
      this.info.fileList = [...new Set([...this.info.fileList])];
    },
    removeFileList() {
      this.model.attachment = "";
    },
    edit() {
      this.show = true;
    },
    messageWarningFn() {
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(() => {
          this.loading = true;
        });
      }, 500);
    },

    async save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true;
          await this.$http.post("/cooperation/intention", this.model);
          this.$Message.success(languageTranslation("提交成功!"));
          this.loading = false;
          this.show = false;
        } else {
          return this.messageWarningFn();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>