import {
    dayjs
} from 'dayjs';
import {
    languageTranslation
} from '../util/language';
export class VaildatorUtil {
    static timeCheck(rule, value, callback) {
        console.log(value);
        if (!value[0] || !value[1]) {

            // callback('开始/结束时间不能为空');
            // callback(new Error(languageTranslation('开始/结束时间不能为空')));
            callback(new Error(languageTranslation('开始/结束时间不能为空')));
        } else {
            callback();
        }
    }
    static vaildateDeadline(rule, value, callback) {

        if (!value) {
            return callback(new Error(languageTranslation('请选择截止日期')));
        } else {
            callback();
        }
    }
}