<template>
    <!-- 校企合作 申请入口页面 -->
    <div class="apply">
        <div class="all-title" style="margin-bottom: 40px">
            {{ " 在线申请流程（以下方式二选一）" | language }}
        </div>
        <div class="list-title mr-b2">
            {{ "方式一、提交合作意向" | language }}
        </div>
        <img :src="isEn ? mth_1_EN : mth_1_CN" class="process" alt="" />
        <div class="list-title mr-b2">
            {{ "方式二、发布" | language }}
        </div>
        <img :src="isEn ? mth_2_EN : mth_2_CN" class="process1" alt="" />
        <div class="title">{{ "没有账号" | language }}</div>
        <div class="btn-group">
            <Button type="primary" size="large" @click="goToLogin">{{
                "注册访客账号" | language
            }}</Button>
        </div>
        <div class="title">{{ "已有账号" | language }}</div>
        <div class="btn-group">
            <Button
                type="primary"
                size="default"
                @click="submitIntention('intention')"
                >{{ "提交合作意向" | language }}</Button
            >
            <Button
                type="primary"
                size="default"
                @click="submitIntention('competition')"
                >{{ "发布竞赛活动" | language }}</Button
            >
            <Button
                type="primary"
                size="default"
                @click="submitIntention('research')"
                >{{ "发布科创课题" | language }}</Button
            >
        </div>
        <dialog-add-intention ref="intention"></dialog-add-intention>
        <dialog-add-competition ref="competition"></dialog-add-competition>
        <dialog-add-research ref="research"></dialog-add-research>
    </div>
</template>

<script>
import { languageTranslation } from "../../assets/util/language";
import DialogAddIntention from "../../compontents/dialog/cooperation/DialogAddIntention.vue";
import DialogAddCompetition from "../../compontents/dialog/cooperation/DialogAddCompetition.vue";
import DialogAddResearch from "../../compontents/dialog/cooperation/DialogAddResearch.vue";
export default {
    components: {
        DialogAddIntention,
        DialogAddCompetition,
        DialogAddResearch,
    },
    data() {
        return {
            mth_1_CN:
                "http://cdn.sjtuair.com/a35c8ab0-4d68-11ea-b916-2dcdd4933621.png",
            mth_1_EN:
                "http://cdn.sjtuair.com/ec8520e0-8471-11ea-a902-d54c323c7bf7.png",
            mth_2_CN:
                "http://cdn.sjtuair.com/a1a2ccc0-4d68-11ea-b916-2dcdd4933621.png",
            mth_2_EN:
                "http://cdn.sjtuair.com/ec856f00-8471-11ea-a902-d54c323c7bf7.png",
        };
    },
    computed: {
        localLanguage() {
            return this.$store.state.language;
        },
        isEn() {
            return this.localLanguage === "en";
        },
    },
    methods: {
        goToLogin() {
            this.$router.push("/common/login");
        },
        async submitIntention(name) {
            if (!this.admin) {
                await this.$Modal.confirm({
                    title: languageTranslation("登录后才能进行此操作"),
                    content: languageTranslation("是否立即跳转到登录页面？"),
                    okText: languageTranslation("确定"),
                    cancelText: languageTranslation("取消"),
                    onOk: () => {
                        this.$router.push("/common/login");
                    },
                });
                return;
            }
            this.$refs[name].edit();
        },
    },
};
</script>

<style lang="less" scoped>
.apply {
    text-align: left;
    .all-title {
        opacity: 0.9;
        font-weight: 600;
        font-size: 30px;
        color: #000000;
        letter-spacing: 0;
        line-height: 32px;
    }
    .title {
        height: 24px;
        font-size: 20px;

        color: rgba(0, 0, 0, 0.6);
        line-height: 24px;
    }
    .list-title {
        height: 32px;
        font-size: 22px;

        font-weight: 500;
        color: #3b95fc;
        opacity: 0.9;
        line-height: 32px;
    }
    .process {
        margin-left: -15px;
        height: 100px;
    }
    .process1 {
        margin-left: -25px;
        height: 115px;
    }
    .btn-group {
        margin-top: 20px;
        margin-bottom: 55px;
        button {
            margin-right: 5px;
            padding: 5px 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
@media screen and (max-width: 960px) {
    .apply {
        .all-title {
            font-size: 20px;
            margin-bottom: 30px;
        }
        .list-title {
            font-size: 18px;
            margin-bottom: 10px;
        }
        .process,
        .process1 {
            width: 100%;
            object-fit: contain;
            margin-left: 0;
            margin-bottom: 0;
            margin-bottom: 30px;
        }
    }
}
</style>
