<template>
    <!-- 发布科创课题弹框 -->
    <Modal v-model="show" :title="'发布科创课题' | language">
        <Form
            :model="model"
            ref="form"
            :rules="ruleValidate"
            label-position="top"
        >
            <FormItem :label="'标题' | language" prop="title">
                <Input
                    v-model="model.title"
                    :placeholder="'请填写标题' | language"
                ></Input>
            </FormItem>
            <FormItem :label="'姓名' | language" prop="name">
                <Input
                    v-model="model.name"
                    :placeholder="'请填写姓名' | language"
                ></Input>
            </FormItem>
            <FormItem :label="'邮箱' | language" prop="email">
                <Input
                    v-model="model.email"
                    :placeholder="'请填写邮箱' | language"
                ></Input>
            </FormItem>
            <FormItem :label="'手机号' | language" prop="phone">
                <Input
                    v-model="model.phone"
                    :placeholder="'请填写手机号' | language"
                ></Input>
            </FormItem>
            <FormItem :label="'项目领域' | language" prop="domain">
                <Input
                    v-model="model.domain"
                    :placeholder="'请填写项目领域' | language"
                ></Input>
            </FormItem>
            <FormItem :label="'面向人员背景' | language" prop="requirement">
                <Input
                    v-model="model.requirement"
                    :placeholder="'请填写面向人员背景' | language"
                ></Input>
            </FormItem>
            <FormItem :label="'项目周期' | language" prop="requireweek">
                <Input
                    v-model="model.requireweek"
                    :placeholder="'请填写项目周期' | language"
                ></Input>
            </FormItem>

            <FormItem :label="'具体描述' | language" prop="desc">
                <Input
                    type="textarea"
                    :autosize="{ minRows: 4 }"
                    v-model="model.desc"
                    :placeholder="'请填写具体描述' | language"
                ></Input>
            </FormItem>
        </Form>
        <div slot="footer" class="container-end">
            <Button size="large" @click="show = false">{{
                "取消" | language
            }}</Button>
            <Button
                type="primary"
                size="large"
                :loading="loading"
                @click="save"
                >{{ "确定" | language }}</Button
            >
        </div>
    </Modal>
</template>

<script>
import { VaildatorUtil } from "../../../assets/util/validator";
import { DayUtil } from "../../../assets/util/day";
import { languageTranslation } from "../../../assets/util/language";
export default {
    data() {
        return {
            show: false,
            loading: false,
            model: {
                title: "",
                name: "",
                email: "",
                phone: "",
                domain: "",
                requirement: "",
                duration: "",
                desc: ""
            },

            ruleValidate: {
                title: [
                    {
                        required: true,
                        message: languageTranslation("标题不能为空"),
                        trigger: "blur"
                    }
                ],
                name: [
                    {
                        required: true,

                        message: languageTranslation("姓名不能为空"),
                        trigger: "blur"
                    }
                ],
                email: [
                    {
                        required: true,

                        message: languageTranslation("邮箱不能为空"),
                        trigger: "blur"
                    },
                    {
                        type: "email",

                        message: languageTranslation("邮箱格式不正确"),
                        trigger: "change"
                    }
                ],
                phone: [
                    {
                        required: true,

                        message: languageTranslation("手机号不能为空"),
                        trigger: "blur"
                    }
                ],
                domain: [
                    {
                        required: true,

                        message: languageTranslation("项目领域不能为空"),
                        trigger: "blur"
                    }
                ],

                requirement: [
                    {
                        required: true,

                        message: languageTranslation("面向人员背景不能为空"),
                        trigger: "blur"
                    }
                ],
                requireweek: [
                    {
                        required: true,

                        message: languageTranslation("项目周期不能为空"),
                        trigger: "blur"
                    }
                ],
                desc: [
                    {
                        required: true,

                        message: languageTranslation("具体描述不能为空"),
                        trigger: "blur"
                    }
                ]
            }
        };
    },

    methods: {
        edit() {
            this.show = true;
        },
        messageWarningFn() {
            setTimeout(() => {
                this.$nextTick(() => {
                    this.loading = false;
                });
            }, 500);
        },

        async save() {
            this.$refs.form.validate(async valid => {
                this.loading = true;
                if (valid) {
                    try {
                        await this.$http.post("/cooperation/research", {
                            ...this.model,
                            duration: this.model.requireweek
                        });
                        this.$Message.success(languageTranslation("提交成功!"));
                        this.show = false;
                    } catch (error) {}

                    this.loading = false;
                } else {
                    return this.messageWarningFn();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
