<template>
	<!-- 发布活动竞赛弹框 -->
	<Modal v-model="show" :title="'发布活动竞赛' | language">
		<Form
			:model="model"
			ref="form"
			:rules="ruleValidate"
			label-position="top"
			:label-colon="true"
		>
			<FormItem :label="'标题' | language" prop="title">
				<Input v-model="model.title" :placeholder="'请填写标题' | language"></Input>
			</FormItem>
			<FormItem :label="'开始-结束时间' | language" prop="date">
				<DatePicker
					type="daterange"
					v-model="model.date"
					:placeholder="'请选择开始-结束时间' | language"
					style="width: 100%"
				></DatePicker>
			</FormItem>
			<!-- <FormItem
                :label="'结束时间'|language"
                prop="endAt"
            >
                <DatePicker
                    v-model="model.endAt"
                    type="date"
                    style="width: 100%"
                    :placeholder="'请选择结束时间'|language"
                ></DatePicker>
            </FormItem> -->
			<FormItem :label="'报名截止时间' | language" prop="deadline">
				<DatePicker
					v-model="model.deadline"
					type="date"
					style="width: 100%"
					:placeholder="'请选择报名截止时间' | language"
				></DatePicker>
			</FormItem>
			<FormItem :label="'报名方式' | language" prop="signupMethod">
				<Input
					v-model="model.signupMethod"
					:placeholder="'请填写报名方式' | language"
				></Input>
			</FormItem>
			<FormItem :label="'具体描述' | language" prop="desc">
				<Input
					type="textarea"
					:autosize="{ minRows: 4 }"
					v-model="model.desc"
					:placeholder="'请填写具体描述' | language"
				></Input>
			</FormItem>
		</Form>
		<div slot="footer" class="container-end">
			<Button size="large" @click="show = false">{{ '取消' | language }}</Button>
			<Button type="primary" size="large" :loading="loading" @click="save">{{
				'确定' | language
			}}</Button>
		</div>
	</Modal>
</template>

<script>
import { VaildatorUtil } from '../../../assets/util/validator';
import { DayUtil } from '../../../assets/util/day';
import { languageTranslation } from '../../../assets/util/language';
export default {
	data() {
		return {
			show: false,
			loading: false,
			model: {
				title: '',
				beginAt: '',
				date: [],
				endAt: '',
				deadline: '',
				signupMethod: '',
				desc: '',
			},

			ruleValidate: {
				title: [
					{
						required: true,
						message: languageTranslation('标题不能为空'),
						trigger: 'blur',
					},
				],
				date: [
					{
						type: 'array',
						required: true,
						validator: VaildatorUtil.timeCheck,
						trigger: 'select',
					},
				],

				deadline: [
					{
						required: true,
						validator: VaildatorUtil.vaildateDeadline,
						// message: languageTranslation("请选择截止日期"),
						trigger: 'blur',
					},
				],
				signupMethod: [
					{
						required: true,

						message: languageTranslation('报名方式不能为空'),
						trigger: 'blur',
					},
				],
				desc: [
					{
						required: true,

						message: languageTranslation('具体描述不能为空'),
						trigger: 'blur',
					},
				],
			},
		};
	},

	methods: {
		edit() {
			this.show = true;
		},
		messageWarningFn() {
			setTimeout(() => {
				this.loading = true;
				this.$nextTick(() => {
					this.$Message.error(languageTranslation('提交失败!'));
					this.loading = false;
				});
			}, 500);
		},

		async save() {
			this.$refs.form.validate(async (valid) => {
				if (valid) {
					this.loading = true;
					await this.$http.post('/cooperation/competition', {
						...this.model,
						beginAt: DayUtil.fmtDate(this.model.date[0]),
						endAt: DayUtil.fmtDate(this.model.date[1]),
						deadline: DayUtil.fmtDate(this.model.deadline),
					});
					this.$Message.success(languageTranslation('提交成功!'));
					this.loading = false;
					this.show = false;
				} else {
					return this.messageWarningFn();
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped></style>
